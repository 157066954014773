@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

#base-page {
  color: $darkgrey;
  text-align: center;

  .title {
    letter-spacing: -0.32px;
    font-size: 32px;
    font-weight: 900;
    margin: 0 0 13px 0;
    @include sm {
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 26px 0;
    }
  }

  .description {
    letter-spacing: 0px;
    opacity: 1;
    margin: 0;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .sub-description {
    margin: 0 0 23px;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  button {
    margin-top: 14px;
    margin-right: 0;
    @include sm {
      margin-top: 0;
      min-width: 180px;
    }
  }
}
