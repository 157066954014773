@import "../../../assets/styles/_variables.scss";

.sigContainer {
  background-color: $aliceblue;
  position: relative;
}
.sigPad {
  width: 100%;
  height: 250px;
  background-color: $aliceblue;
  position: relative;
}

.signatureButtonFlexbox {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
}

.signatureButtonContainer {
  margin-right: 10px;
}
