@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.upload-wrap {
  display: none;
  opacity: 0;
}

.upload-btn {
  width: 190px;
  height: 55px;
  background-color: $royalblue;
  color: $white;
  box-shadow: 0px 3px 20px $shadow;
  border-radius: 4px;
  @include sm {
    margin: 0 auto;
    width: 100%;
  }
  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}
