@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.steps-section {
  &.width-66 {
    width: 66%;
  }

  .MuiPaper-root {
    background-color: transparent;
  }

  .MuiStepLabel-root {
    @include custom(600) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  .MuiStepLabel-label {
    &.MuiStepLabel-active {
      color: $royalblue;
      text-align: center;
      letter-spacing: 0px;
      margin-left: 25px;
      @include sm {
        text-align: center;
        margin-left: 0;
      }
    }

    &.MuiStepLabel-completed {
      color: $royalblue;
    }
  }

  .MuiStepper-root {
    padding: 0 23px;

    @include custom(600) {
      padding: 0 16px;
    }
  }

  .MuiStep-horizontal {
    display: flex;
    align-items: center;
    width: 200px;
    height: 64px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px $shadow;
    border-radius: 10px;

    @include custom(600) {
      width: 70px;
      height: 96px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    @include custom(350) {
      width: 60px;
    }
  }

  .MuiStepLabel-iconContainer {
    margin-left: 9px;
    @include sm {
      margin-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
    }
  }
  .MuiStepIcon-root {
    border-width: 1px;
    border-style: solid;
    border-color: $grey;
    border-image: initial;
    border-radius: 50% 50% 50% 50%;
    padding: 3% 3% 3% 3%;
    color: $white;
    opacity: 1;
    &.MuiStepIcon-active {
      border-color: $royalblue;
      color: $white;
    }
    &.MuiStepIcon-completed {
      color: $lightgreen;
      padding: 0;
      border-color: $lightgreen;
    }
  }

  .MuiSvgIcon-root {
    &.MuiStepIcon-root {
      &.MuiStepIcon-active {
        .MuiStepIcon-text {
          fill: $royalblue;
          font-size: 14px;
        }
      }
    }
  }

  .MuiStepIcon-text {
    fill: $grey;
    font-size: 14px;
  }
}
