@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.page-container {
  .title {
    color: $darkgrey;
    text-align: center;
    letter-spacing: -0.32px;
    color: #2d333d;
    font-weight: 900;
    font-size: 32px;

    @include sm {
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
    }
  }

  .card-information {
    text-align: center;
    letter-spacing: 0px;
    margin: 27px;
    font-size: 18px;
    p {
      text-align: center;
      letter-spacing: 0px;
      margin: 0;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.01em;
      text-align: center;
    }
    @include sm {
      margin: 24px 0 7px 0;
      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
    &.left p,
    ul {
      text-align: left;
    }
  }

  .sub-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    letter-spacing: -0.24px;
    color: #2d333d;
    font-weight: bold;
  }

  .info_icon {
    width: 27px;
    height: 27px;
    color: $royalblue;
  }
}
