@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.form-control {
  width: 100%;
  @include md {
    svg {
      display: inline;
    }
  }

  .MuiSelect-select {
    &:focus {
      background-color: $aliceblue;
    }
  }
  .MuiFilledInput-root {
    background-color: $aliceblue;
    &.Mui-focused {
      background-color: $aliceblue;
    }
    &:hover {
      background-color: $aliceblue;
    }
  }

  @include sm {
    .MuiPaper-root {
      background-color: red;
    }

    .MuiSelect-root {
      font-size: 14px;
      line-height: 24.5px;
    }
    .MuiFormLabel-root {
      font-size: 14px;
      line-height: 24.5px;
    }
    .MuiSvgIcon-root {
      width: 0.8em;
    }
  }
  .MuiFormHelperText-root {
    &.MuiFormHelperText-contained {
      &.MuiFormHelperText-filled {
        color: red;
      }
    }
  }
}

@include sm {
  .MuiMenuItem-root {
    font-size: 14px !important;
    line-height: 24.5px;
  }
}
