html,
body,
#root {
  margin: 0;
  height: 100%;
}

body {
  min-width: 320px;
  font-family: "Overpass", "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
