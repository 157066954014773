$screen-xs-min: 320px;
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-md-height-min: 640px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

@mixin xs {
 @media (max-width: #{$screen-xs-min}) {
   @content;
 }
}

@mixin sm {
 @media (max-width: #{$screen-sm-min}) {
   @content;
 }
}

@mixin md {
 @media (max-width: #{$screen-md-min}) {
   @content;
 }
}

@mixin lg {
 @media (max-width: #{$screen-lg-min}) {
   @content;
 }
}

@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
   @content;
  }
}

@mixin custom($screen) {
 @media (max-width: $screen+'px') {
   @content;
 }
}

@mixin md-height {
  @media (max-height: #{$screen-md-height-min}) {
    @content;
  }
}

@mixin md-device {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) and (max-height: #{$screen-md-height-min}) {
    @content;
  }
}
