@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.checkbox-aria {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  .MuiCheckbox-root {
    max-height: 25px;
    display: flex;
    justify-content: center;
  }

  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: $royalblue;
    }
  }

  .PrivateSwitchBase-root-13 {
    margin: -9px 0 0 0;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin-left: -9px;
    @include sm {
      margin-right: 0px;
    }
  }

  .description {
    margin-top: 5px;
    text-align: left;
    letter-spacing: 0px;
    color: $grey;
    padding-left: 0px;
    p {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.01em;
      @include sm {
        margin-left: 14px;
      }
    }
  }

  .Mui-checked {
    .MuiSvgIcon-root {
      box-shadow: none;

      path {
        display: initial;
      }
    }
  }

  .MuiSvgIcon-root {
    box-shadow: inset 0px 4px 10px rgba(17, 52, 131, 0.2);
    border-radius: 4px;
    width: 24px;
    height: 24px;

    path {
      display: none;
    }
  }

  @include md {
    .MuiGrid-grid-xs-8 {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
