@import "../../../assets/styles/responsive.scss";

.action-wrap {
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.single-button {
    justify-content: flex-end;
  }

  .MuiButtonBase-root {
    margin-right: 0;
  }
}
