@import "../../../assets/styles/responsive.scss";
@import "../../../assets/styles/_variables.scss";

.text-field {
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  input {
    background-color: $aliceblue;
    border-radius: 4px 4px 0px 0px;
  }
  svg {
    margin-right: 8px;
  }
  .MuiFormLabel-root {
    display: flex;
    align-items: center;
  }
  @include md {
    .MuiFilledInput-underline {
      &:after,
      &::before {
        left: 0;
        width: 100%;
      }
    }
  }
  .MuiFilledInput-underline {
    &::before {
      display: none;
    }
  }
  @include sm {
    .MuiFilledInput-root {
      font-size: 14px;
      line-height: 24.5px;
    }
    .MuiFormLabel-root {
      font-size: 14px;
      line-height: 24.5px;
    }
  }
}
