@import "../../../assets/styles/responsive.scss";
@import "../../../assets/styles/_variables.scss";

.section {
  &:not(:first-child) {
    padding-left: 20px;
    @include md {
      padding-left: 0px;
    }
  }

  @include md {
    padding-left: 0px;
    width: 100%;
      margin-bottom: 20px;

    .MuiFilledInput-underline {
      &:after,
      &::before {
        left: 0;
        width: 100%;
      }
    }
  }
  .MuiFormControl-root {
    width: 360px;
    .MuiFilledInput-underline {
      &::before {
        display: none;
      }
    }
  }
  .MuiFormLabel-root {
    display: flex;
    align-items: center;
    @include sm {
      font-size: 14px;
      line-height: 24.5px;
    }
  }
  &__small-size {
    .MuiFormControl-root {
      width: 200px;
    }
  }

  .text-field {
    border-radius: 4px 4px 0px 0px;

    input {
      background-color: $aliceblue;
      border-radius: 4px 4px 0px 0px;
    }

    @include sm {
      flex-direction: column;
      width: 100%;
      .MuiInputBase-root{
        font-size: 14px;
        line-height: 24.5px;
      }
    }
  }
}
