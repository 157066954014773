@import "../../../assets/styles/_variables.scss";
@import "../../../assets/styles/responsive.scss";

.card {
  min-height: 100px;
  box-shadow: 0px 3px 20px $shadow;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 48px;
  margin-top: 40px;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }

  @include md {
    min-width: auto;
  }

  @include sm {
    padding: 20px;
    margin-bottom: 40px;
  }

  .card-description {
    margin-top: 10px;
    margin-bottom: 35px;

    @include sm {
      margin-bottom: 33px;
    }
  }
  &.selected {
    border: 2px solid $royalblue;
    overflow: initial;
  }
  &.curserPointer {
    cursor: pointer;
  }
  &.selected-success {
    border: 2px solid $lightgreen;
  }
}
